<template>
  <span
    v-if="label"
    class="label label--marketing"
    :style="style"
    v-text="label"
  />
</template>

<script setup lang="ts">
import type { EsProduct } from '~/types/elastic'

const props = defineProps<{ product: EsProduct }>()

const label = computed<string>(() =>
  props.product?.marketingLabel?.label || '',
)

const style = computed<{ backgroundColor?: string }>(() => {
  return {
    backgroundColor: props.product.marketingLabel?.swatch || undefined,
  }
})
</script>

<style src="~layers/app/components/Product/Labels.css" />
