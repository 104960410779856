<template>
  <button
    class="wishlist-toggle"
    @click="toggleWishlist(product.sku)"
  >
    <template v-if="!isInWishlist">
      <span class="wishlist-toggle__label visually-hidden">Add to Wishlist</span>
      <IconHeartEmpty
        class="wishlist-toggle__icon icon icon--wishlist"
        @click="showNotification({ type: 'success', message: $t('{name} has been added to your wishlist.', { name: product.name }) })"
      />
    </template>
    <template v-else>
      <span class="wishlist-toggle__label visually-hidden">Remove from Wishlist</span>
      <IconHeartFilled
        class="wishlist-toggle__icon icon icon--wishlist"
        @click="showNotification({ type: 'success', message: $t('{name} has been removed from your wishlist.', { name: product.name }) })"
      />
    </template>
  </button>
</template>

<script lang="ts" setup>
export interface Props {
  product: {
    sku: string
    name: string
  }
}
const props = defineProps<Props>()
const { wishlist, toggleWishlist } = useWishlist()
const { show: showNotification } = useNotification()
const isInWishlist = computed(() => wishlist.value.find(item => item.sku === props.product.sku))
</script>

<style src="~layers/app/components/Product/ToggleWishlist.css" />
