<template>
  <div
    v-if="colorVariants.length"
    class="color-variant"
  >
    <AppLink
      v-for="(colorVariant, index) in limitedColorVariants"
      :key="index"
      :to="colorVariant.url"
      class="color-variant__swatch"
      :class="[
        colorVariant.stockStatus ? 'in-stock' : 'out-of-stock',
      ]"
      :style="swatch(colorVariant?.color?.swatch)"
    >
      &nbsp;
    </AppLink>

    <span
      v-if="colorVariants.length > limitedColorVariants.length"
      class="color-variant__more"
    >
      + {{ colorVariants.length - limitedColorVariants.length }}
    </span>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  colorVariants: {
    url: string
    color: {
      label: string
      swatch: string
    }
    stockStatus?: boolean
  }[]
  limit?: number
}

const props = withDefaults(defineProps<Props>(), {
  limit: 4,
})

const { public: config } = useRuntimeConfig()
const swatch = (swatch: string | undefined) => {
  const swatchPath = config.baseDomain + '/media/attribute/swatch'
  return swatch?.startsWith('#') ? `background-color: ${swatch}` : `background-image: url(${swatchPath + swatch}); border: 0;`
}

const limitedColorVariants = computed(() =>
  props.colorVariants.slice(0, props.colorVariants.length > props.limit ? props.limit - 1 : props.colorVariants.length),
)
</script>

<style src="~layers/app/components/Product/ColorVariant.css" />
