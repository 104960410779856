<template>
  <article
    v-if="product"
    class="product-item"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
  >
    <component
      :is="getElement()"
      :to="`${product.url}${parameter}`"
      class="product-item__link"
      :class="{
        'sqzl-click': product?.squeezely,
      }"
      :data-sqzl-personalization-id="product?.squeezely?.personalizationId"
      :data-sqzl-variant-id="product?.squeezely?.variantId"
      :data-sqzl-variant-revision="product?.squeezely?.variantRevision"
      @click="trackProductClick({ product: product, dataLayerOverrides: dataLayerOverrides })"
    >
      <div class="product-item__visual">
        <img
          class="product-item__image"
          :src="imageSrc"
          width="400"
          height="400"
          :loading="lazy ? 'lazy' : 'eager'"
          :fetchpriority="lazy ? 'low' : 'high'"
          :decoding="lazy ? 'async' : 'sync'"
          :alt="product.name || ''"
        >
        <div class="product-item__labels">
          <ProductLabelSale
            v-if="product.isSale && product.discount >= 40"
            :prices="{
              regularPrice: product.regularPrice,
              finalPrice: product.finalPrice,
            }"
          />

          <ProductLabelMarketing
            v-if="product.marketingLabel"
            :product="product"
          />
        </div>
      </div>
    </component>

    <div class="product-item__actions">
      <ProductToggleWishlist
        class="product-item__wishlist"
        :product="product"
      />

      <ProductColorVariant
        v-if="product?.relatedColorProducts?.length"
        :color-variants="product.relatedColorProducts"
        class="product-item__color-variants"
      />
    </div>

    <component
      :is="getElement()"
      :to="`${product.url}${parameter}`"
      class="product-item__link"
      :class="{
        'sqzl-click': product?.squeezely,
      }"
      :data-sqzl-personalization-id="product?.squeezely?.personalizationId"
      :data-sqzl-variant-id="product?.squeezely?.variantId"
      :data-sqzl-variant-revision="product?.squeezely?.variantRevision"
      @click="trackProductClick({ product: product, dataLayerOverrides: dataLayerOverrides })"
    >
      <div class="product-item__details">
        <span class="product-item__brand">
          {{ product?.brand?.label }}
        </span>

        <span
          v-if="showDescription"
          class="product-item__description"
        >
          {{ product.name }}
        </span>

        <ProductPrice
          v-if="showPrice"
          class="product-item__price"
          :prices="{
            fromPrice: product.type === 'configurable' ? product.finalPrice : undefined,
            regularPrice: product.regularPrice,
            finalPrice: product.finalPrice,
          }"
          :show-vat-tag="false"
        />
      </div>
    </component>

    <template v-if="showCta">
      <button
        v-if="product.type === 'simple'"
        class="product-item__button button button--shop-flow button--icon-only button--small"
        type="button"
        :disabled="pending"
        @click="add"
      >
        <IconShoppingcart class="icon icon--cart" />
        <!-- <span>{{ $t('Add to cart') }}</span> -->
      </button>

      <AppLink
        v-else
        :to="`${product.url}${parameter}`"
        class="product-item__button button button--shop-flow button--icon-only button--small"
      >
        <IconArrow class="icon icon--arrow-right" />
        <!-- <span>{{ $t('View product') }}</span> -->
      </AppLink>
    </template>

    <slot />
  </article>
</template>

<script lang="ts" setup>
import type { EsProduct } from '~/types/elastic'
import type { DataLayerItem } from '~/composables/useDataLayer'
import { AppLink } from '#components'

const props = withDefaults(
  defineProps<{
    product: EsProduct
    lazy: boolean
    list?: string
    parameter?: string
    dataLayerOverrides?: Partial<DataLayerItem>
    linkable?: boolean
    showDescription?: boolean
    showPrice?: boolean
    showCta?: boolean
  }>(),
  {
    list: 'other',
    parameter: '',
    dataLayerOverrides: undefined,
    linkable: true,
    showDescription: true,
    showPrice: true,
    showCta: false,
  },
)

const { product } = toRefs(props)

const hover = ref(false)

const imageSrc = computed(() => {
  let imageSrc = product.value.image

  if (hover.value && product.value.hover_image) {
    imageSrc = product.value.hover_image
  }

  return useProductImage(imageSrc, 'f=rs:fit:400:400', product.value.name)
})

function getElement() {
  return product.value.url && props.linkable ? AppLink : 'div'
}

function mouseOver() {
  hover.value = true
}

function mouseOut() {
  hover.value = false
}

const emit = defineEmits(['productClick'])
const { selectItem } = useDataLayer()

const trackProductClick = ({ product, dataLayerOverrides }: { product: EsProduct, dataLayerOverrides: any }) => {
  selectItem(product, dataLayerOverrides)
  emit('productClick', product)
}

const { addToCart } = useCart()
const pending = ref(false)

const add = async () => {
  pending.value = true

  await addToCart([{
    data: {
      quantity: 1,
      sku: product.value.sku,
    },
  }], false)

  pending.value = false
}
</script>

<style src="~layers/app/components/Product/Tile.css" />
